import * as React from "react";
import {Link} from "gatsby";
import {graphql} from "gatsby";
import styled, {keyframes} from "styled-components"
import { breakpoint } from "styled-components-breakpoint";

import ButtonMovingFrames from "./../components/buttonMovingFrames"
import NextBurialDate from "./../components/nextBurialDate"
import Article from "./../components/article"
import Pagination from '@material-ui/lab/Pagination';

import SEO from './../components/SEO';


import "normalize.css"


const HomeContainer = styled.main`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  align-self: center;

  color: #363537;
  font-family: 'Quicksand',sans-serif;
  text-transform: uppercase;
  text-align: center;


  margin: 60px 0px 0px 0px;
  font-size: 48px;
  ${breakpoint("tablet") `
      margin: 80px 40px 0px 40px;
      font-size: 56px;
    `}
    ${breakpoint("desktop") `
        font-size: 64px;
        margin: 100px 40px 0px 40px;
    `}
`

const CTAButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  margin: 125px 0px;

  & a{
    text-decoration: none;
  }
`

const Blog = styled.div`
  display: flex;
  flex-direction: column;

  background: #fbfbfb;
`

const BlogHeader = styled.div`
  align-self: center;

  font-size: 42px;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #363537;

  margin: 30px 0px 0px 0px;
  padding: 0px 20px;

  position: relative;
  display: flex;
  flex-direction: column;
`

const WidthAnimation = keyframes`
    from{
        width: 0;
    }
    to{
        width: 120%;
    }
`

const HeaderDecoration = styled.div`
    background: #FF7171;
    height: 3px;
    width: 70%;
    ${breakpoint("tablet") `
      width: 120%;
    `}
    align-self: center;
    margin-top: 5px;

    animation: ${WidthAnimation} .8s ease-in-out;
`

const DateIdicator = styled.div`
  align-self: center;
  margin: 20px 0px;

  font-size: 26px;
  font-family: 'Quicksand', sans-serif;
  color: #363537;
  text-align: center;
`

const SingleDateContainer = styled.div`
  margin: 8px 0px;
`

const PaginationContainer = styled.div`
  width: fit-content;
  align-self: center;

  margin: 15px 0px;
`

const ArticleContainerStyles=styled.div`
  display: flex;
  flex-direction: column;
`


export const getArticles = graphql`
query getArticles {
  allDatoCmsArticle(
    sort: {order: DESC, fields: meta___createdAt}
  ) {
    nodes {
      slug
      title
      date
      shorttext
      body {
        value
      }
    }
    totalCount
  }
} 
`;

const ArticleContainer = (props) => {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  let content = [];
  let key=0;
  let articles = props.articles;
  for(const item in articles){
    if(item>=((page*5)-5) && item<(page*5)) content.push(<Article key={++key} title={articles[item].title} date={articles[item].date} slug={articles[item].slug} shorttext={articles[item].shorttext}></Article>);
  }

  return (
    <>
      <ArticleContainerStyles>
        {content}
      </ArticleContainerStyles>
      <PaginationContainer><Pagination count={Math.round(props.totalCount/5+0.5)} page={page} onChange={handleChange}/></PaginationContainer>
    </>
  )
}

const IndexPage = ({data}) => {
  return (
    <HomeContainer>
      <SEO title="Miejsce Pamięci Dzieci Utraconych w Dębicy" link="https://debica-dzieciutracone.pl" ogTitle="Miejsce Pamięci Dzieci Utraconych w Dębicy" description="Strona poświęcona miejscu pamięci dzieci utraconych w Dębicy na cmentarzu przy ul. Wielopolskiej. Pochówki odbywają się dwa razy w roku: w pierwszą niedzielę po Dniu Świętości Życia(25 marca) oraz w niedzielę po wspomnieniu Dziecka Utraconego(15 październik)."/>

      <Title>Miejsce pamięci dzieci utraconych w{'\u00A0'}Dębicy</Title>
      <CTAButtons>
        <Link to="/o-pochowku/"><ButtonMovingFrames>O pochówku</ButtonMovingFrames></Link>
        <Link to="/formularz/"><ButtonMovingFrames>Formularz</ButtonMovingFrames></Link>
        <Link to="/ksiega-pamieci/"><ButtonMovingFrames>Księga pamięci</ButtonMovingFrames></Link>
      </CTAButtons>

      <Blog>
          <BlogHeader>
            Aktualności
            <HeaderDecoration/>
          </BlogHeader>
          <DateIdicator>
            <SingleDateContainer>Najbliższy pochówek odbędzie się <strong><NextBurialDate/></strong></SingleDateContainer>
            <SingleDateContainer>Najbliższa Msza Św. w intencji dzieci utraconych odbędzie się: <strong>8.04.2024r. o godz 18:00</strong></SingleDateContainer>
          </DateIdicator>
          <ArticleContainer articles={data.allDatoCmsArticle.nodes} totalCount={data.allDatoCmsArticle.totalCount}/>
      </Blog>  
    </HomeContainer>
  )
}

export default IndexPage
